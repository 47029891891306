import React from 'react'
import { InlineWidget } from "react-calendly";
import Layout from '../components/layout';
import Img from "gatsby-image"
import Logo from "../images/logo-04.png";

const Onboarding = () => {
    return (
        <Layout>
              <div className="demo-header_nav">
                
                    <div className="merchants-header_textContainer">
                        <div style={{display:'flex', marginBottom:'0.5rem', justifyContent:'center'}}>
                            <img src={Logo} alt="logo"  style={{width:'3rem', margin:'0', cursor:'pointer'}} />
                            <h1 className="navbar-logo_text" style={{fontSize:'3rem'}}>Sizify</h1>
                        </div>
                        <h2 className="merchants-featured_title" style={{textAlign:'center'}}>Welcome To Onboarding</h2>
        
                        <h3 className="merchants-featured_subTitle" style={{textAlign:'center'}}>
                        Follow the steps provided to complete onboarding process.
                        </h3>
                
                    </div>
                </div>
              <div className="demo-section_bg" >
                      <div className="demo-header_container " >
                     
                        <div className="demo-feature_textContainer" >
                        <h2 className="demo-calendar_title">Onboarding Steps</h2>
        
                          <ol>
                            <li className="demo-checklist-li-text">
                             <p>Install our Shopify app using the button below. </p>
                              </li>
                              <li className="demo-checklist-li-text">
                        
                             <p>Once app is installed, choose a plan.</p>
                              </li>
                              <li className="demo-checklist-li-text">
                     
                             <p>Next, our team will reach out (within 24 hours). We ask that you accept our request for limited store access. This is required for our team to complete software onboarding on your behalf. </p>
                              </li>
                              <li className="demo-checklist-li-text">
                     
                             <p>Once our team has completed the onboarding, you will be notified via email when the widget will go live on your storefront. </p>
                              </li>
                           
                            </ol>
                        <a  style={{textDecoration:'none'}}href="https://www.shopify.com/login?redirect=/api/auth&api_key=fe588676a72eee0d55f4ebbe38589d74">
                            <button className="btn btn-primary" >
                            Install Sizify's Shopify App & Start Onboarding
                            </button>
                        </a>
                        </div>
                    
                        
                      </div>
                
                </div>
               
        
               
        
          </Layout>
          )
}

export default Onboarding